import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import { CustomInputWrapper, CustomFormItem, InputActions, BusinessSearchModal } from '../../components';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';
import { SearchIcon } from '../../../custom_shared/icons';
import { useAppStore } from '../../stores';
import { BusinessDto } from '../../types';
import { ClickableIcon } from '../../../custom_shared/components';
import { InputIds } from '../../misc';

const config = {
    label: 'Business ID'
};

const BusinessIdInput: InputWithoutSource = ({ input }) => {
    const { tabStore, businessSearchStore, contractSubmissionStore } = useAppStore();

    const onBusinessClick = (business: BusinessDto) => {
        input.setValue(business.Identifier);
        input.validate();

        const expiringPeriodStartInput = tabStore.generalInformationTab?.inputs.find(
            i => i.id === InputIds.GeneralInformation.ExpiringPeriodStart
        );

        if (expiringPeriodStartInput) {
            expiringPeriodStartInput.setValue(business.InsuredPeriodStart);
        }

        businessSearchStore.setModalVisible(false);
    };

    return (
        <>
            <BusinessSearchModal onBusinessClick={onBusinessClick} />

            <CustomFormItem name={input.guid} required={input.required}>
                <CustomInputWrapper
                    input={input}
                    label={config.label}
                    allowClear
                    topRightNode={
                        <InputActions
                            input={input}
                            additionalLeftNode={
                                contractSubmissionStore.contractSent ? null : (
                                    <Tooltip title="Business Search">
                                        <ClickableIcon
                                            width={17}
                                            height={17}
                                            color="#000"
                                            icon={SearchIcon}
                                            onClick={() => businessSearchStore.setModalVisible(true)}
                                        />
                                    </Tooltip>
                                )
                            }
                        />
                    }
                />
            </CustomFormItem>
        </>
    );
};

BusinessIdInput.meta = {
    inputType: InputModel.name
};

export default observer(BusinessIdInput);
