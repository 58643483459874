import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useAppStore } from '../../stores';
import { CheckIconOutlined } from '../../../custom_shared/icons';
import './FlowResponseRenderer.less';

const FlowResponseRenderer: React.FC = () => {
    const { contractSubmissionStore } = useAppStore();

    if (!contractSubmissionStore.flowResponse) {
        return null;
    }

    return (
        <div className="flow-response-renderer-container">
            <div className="flow-response-renderer-content">
                <div className="flow-response-renderer-left-column">
                    <CheckIconOutlined />
                </div>

                <div className="flow-response-renderer-right-column">
                    <span>
                        The contract was successfully
                        {contractSubmissionStore.flowResponse.isRenewal ? ' updated' : ' created'}.
                    </span>

                    {contractSubmissionStore.flowResponse.contractTitle && (
                        <div className="list-item">
                            <span className="list-item-key">Contract title:</span>
                            <span className="list-item-value">
                                {contractSubmissionStore.flowResponse.contractTitle}
                            </span>
                        </div>
                    )}

                    {contractSubmissionStore.flowResponse.programId && (
                        <div className="list-item">
                            <span className="list-item-key">Program ID:</span>
                            <span className="list-item-value">{contractSubmissionStore.flowResponse.programId}</span>
                        </div>
                    )}

                    {contractSubmissionStore.flowResponse.businessIds.map((businessId, index) => (
                        <div key={`${businessId}-${index}`} className="list-item">
                            <span className="list-item-key">Business ID:</span>
                            <span className="list-item-value">{businessId}</span>
                            <Tooltip title="Copy">
                                <CopyOutlined
                                    className="copy-icon"
                                    onClick={() => navigator.clipboard.writeText(businessId)}
                                />
                            </Tooltip>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default observer(FlowResponseRenderer);
