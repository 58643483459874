import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { PlusIcon } from '../../../custom_shared/icons';
import { useAppStore } from '../../stores';
import { ButtonWithIcon } from '../../../custom_shared/components';
import { ActionParams } from '../../types';

interface Props {
    actionParams: ActionParams;
}

const AddDeductionButton: React.FC<Props> = ({ actionParams }: Props) => {
    const { actionStore, controlStateStore } = useAppStore();

    return (
        <ButtonWithIcon
            label="Add deduction"
            icon={<PlusIcon />}
            onClick={() => actionStore.addDeduction(actionParams)}
            disabled={controlStateStore.actionsDisabled}
        />
    );
};

export default observer(AddDeductionButton);
