import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DocumentsIcon, CheckIcon, ExclamationCircleIcon } from '../../../custom_shared/icons';
import { useAppStore } from '../../stores';
import './RenewalSuggestionsControls.less';

const RenewalSuggestionsControls: React.FC = () => {
    const { renewalSuggestionsStore, contractSubmissionStore } = useAppStore();

    if (!renewalSuggestionsStore.controlsVisible || contractSubmissionStore.contractSendingOrSent) {
        return null;
    }

    const renderSearchAgainButton = () => (
        <Button type="primary" onClick={() => renewalSuggestionsStore.searchRenewalSuggestions()}>
            Search again
        </Button>
    );

    const renderCancelButton = () => (
        <Button
            onClick={() => {
                renewalSuggestionsStore.cancelRenewalSuggestionsSearch();
                renewalSuggestionsStore.disableRenewalSuggestionsSearch();
            }}
        >
            Cancel
        </Button>
    );

    const renderCloseButton = () => (
        <Button onClick={() => renewalSuggestionsStore.disableRenewalSuggestionsSearch()}>Close</Button>
    );

    const renderViewResultsButton = () => (
        <Button type="primary" onClick={() => renewalSuggestionsStore.setModalVisible(true)}>
            View results
        </Button>
    );

    if (renewalSuggestionsStore.searchingSuggestions) {
        return (
            <div className="renewal-suggestions-controls-container">
                <Spin indicator={<LoadingOutlined spin />} />
                <span className="info-label">Processing the contract data to find renewal suggestions...</span>
                {renderCancelButton()}
            </div>
        );
    }

    if (renewalSuggestionsStore.error) {
        return (
            <div className="renewal-suggestions-controls-container">
                <ExclamationCircleIcon width={24} height={24} />
                <span className="info-label">{renewalSuggestionsStore.error}</span>
                {renderSearchAgainButton()}
                {renderCloseButton()}
            </div>
        );
    }

    if (!renewalSuggestionsStore.searchingSuggestions && !renewalSuggestionsStore.state.suggestions.length) {
        return (
            <div className="renewal-suggestions-controls-container">
                <CheckIcon />
                <span className="info-label">Nothing found</span>
                {renderSearchAgainButton()}
                {renderCloseButton()}
            </div>
        );
    }

    return (
        <div className="renewal-suggestions-controls-container">
            <DocumentsIcon />
            <span className="info-label">Potential contract for renewal found</span>
            {renderViewResultsButton()}
        </div>
    );
};

export default observer(RenewalSuggestionsControls);
