/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const DocumentsIcon: React.FC<IconProps> = ({ width = 24, height = 24, color = '#717887', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.03906 4.20312H15.6172C15.5703 3.54688 15.1953 3.19531 14.4766 3.19531H9.17969C8.46094 3.19531 8.08594 3.54688 8.03906 4.20312ZM6.64844 6.36719H16.9375C16.8203 5.66406 16.4844 5.26562 15.7031 5.26562H7.89062C7.10156 5.26562 6.76562 5.66406 6.64844 6.36719ZM7.44531 21.4766H16.6172C18.0156 21.4766 18.8359 20.6641 18.8359 19.0547V10.0547C18.8359 8.44531 18.0078 7.63281 16.3828 7.63281H7.44531C5.8125 7.63281 4.99219 8.4375 4.99219 10.0547V19.0547C4.99219 20.6641 5.8125 21.4766 7.44531 21.4766ZM7.46094 20.2188C6.67969 20.2188 6.25 19.7969 6.25 18.9844V10.1172C6.25 9.30469 6.67969 8.89062 7.46094 8.89062H16.3594C17.1328 8.89062 17.5781 9.30469 17.5781 10.1172V18.9844C17.5781 19.7969 17.1328 20.2188 16.5938 20.2188H7.46094Z"
            fill={color}
        />
    </svg>
);

export default DocumentsIcon;
