import moment, { Moment } from 'moment-timezone';
import { TypeUtils } from '../../custom_shared/misc';
import { Regex } from '.';

const DATE_STRING_FOMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export default class Utils {
    static convertDateValueToMoment(value: unknown): Moment | null {
        if (TypeUtils.isMoment(value)) {
            return value;
        }

        return TypeUtils.isDate(value) ? moment(value) : null;
    }

    static convertDateValueToString(value: unknown): string | null {
        if (TypeUtils.isMoment(value)) {
            return value.format(DATE_STRING_FOMAT);
        }

        return TypeUtils.isDate(value) ? moment(value).format(DATE_STRING_FOMAT) : null;
    }

    static convertToStringArray(value: unknown): string[] {
        if (!value) {
            return [];
        }

        if (TypeUtils.isStringArray(value)) {
            return value;
        }

        if (typeof value === 'string') {
            return [value];
        }

        return [];
    }

    static groupArray<T>(inputArray: T[], groupSize: number = 4) {
        return inputArray.reduce((acc: T[][], currentValue: T, currentIndex: number) => {
            const groupIndex = Math.floor(currentIndex / groupSize);

            if (!acc[groupIndex]) {
                acc[groupIndex] = [];
            }

            acc[groupIndex].push(currentValue);

            return acc;
        }, []);
    }

    // Export types are missing for react-maskinput lib
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static reformatMaskedInput(params: any) {
        const regex = Regex.decimalRegex;
        let value = params.visibleValue ?? params.value;

        const resetValue = () => {
            params.value = params.visibleValue = '';
            params.selection.start = params.visibleValue.length;
            params.selection.end = params.visibleValue.length;
            return params;
        };

        if (!TypeUtils.isString(value)) {
            return resetValue();
        }

        value = value.replace(regex, ',') as string;
        const inputValue = (params.input ?? '') as string;

        params.visibleValue = (
            value.substring(0, params.selection.start) +
            inputValue +
            value.substring(params.selection.end)
        ).replace(regex, ',');

        if (params.visibleValue.trim().length === 0) {
            return resetValue();
        }

        params.selection.start = params.visibleValue.length;
        params.selection.end = params.visibleValue.length;

        return params;
    }

    static createNumberArray(length: number) {
        return Array.from({ length }, (_, index) => index + 1);
    }

    static formatAmount(value: unknown) {
        const formatString = (str: string) => str.replace(Regex.decimalRegex, ',');

        if (TypeUtils.isString(value)) {
            return formatString(value);
        }

        if (TypeUtils.isNumber(value)) {
            return formatString(value.toString());
        }

        return '';
    }

    static filterEmptyEntries<T extends Record<string, unknown>>(obj: T) {
        return Object.fromEntries(Object.entries(obj).filter(entry => entry[1] !== undefined));
    }
}
