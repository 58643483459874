import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import { InputRenderer } from '../../components';
import { TabProps } from '../../componentTypes';
import {
    InstalmentsCountInput,
    InstalmentTypeInput,
    FirstInstalmentCountInput,
    FirstInstalmentPeriodInput,
    SubsequentInstalmentCountInput,
    SubsequentInstalmentPeriodInput
} from '../../tab_inputs/InstalmentsTabInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils, InputIds } from '../../misc';
import { useTabForm } from '../../hooks';

const InputComponents: Record<string, React.FunctionComponent> = {
    [InputIds.Instalments.InstalmentsCount]: InstalmentsCountInput,
    [InputIds.Instalments.InstalmentType]: InstalmentTypeInput,
    [InputIds.Instalments.FirstInstalmentCount]: FirstInstalmentCountInput,
    [InputIds.Instalments.FirstInstalmentPeriod]: FirstInstalmentPeriodInput,
    [InputIds.Instalments.SubsequentInstalmentCount]: SubsequentInstalmentCountInput,
    [InputIds.Instalments.SubsequentInstalmentPeriod]: SubsequentInstalmentPeriodInput
} as const;

const InstalmentsTabRenderer: React.FC<TabProps> = ({ tab }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab);

    const getComponentRows = React.useCallback(
        (inputs: InputBaseModel[]) =>
            Utils.groupArray(inputs, 3).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        []
    );

    return (
        <LayoutRenderer
            tab={tab}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(InstalmentsTabRenderer);
