import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import { BasicTabModel, LayersTabModel, LayersWithSectionsTabModel, SectionsTabModel } from '../../models';
import { InstanceUtils } from '../../misc';
import TabRenderer from './TabRenderer';
import './TabsRenderer.less';

const TabsRenderer: React.FC = () => {
    const { tabStore, inputStore, documentVisualStore } = useAppStore();

    const handleBasicTabExpand = React.useCallback(
        (tab: BasicTabModel, inputGuid: string) => {
            let addDelay = false;

            if (!tab.expanded) {
                tab.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    const handleLayersTabExpand = React.useCallback(
        (tab: LayersTabModel, inputGuid: string) => {
            const layer = tab.getLayerByInputGuid(inputGuid);

            if (!layer) {
                return;
            }

            let addDelay = false;

            if (!layer.expanded) {
                layer.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    const handleLayersWithSectionsTabExpand = React.useCallback(
        (tab: LayersWithSectionsTabModel, inputGuid: string) => {
            const layer = tab.getLayerByInputGuid(inputGuid);

            if (!layer) {
                return;
            }

            let addDelay = false;

            if (!layer.expanded) {
                layer.setExpanded(true);
                addDelay = true;
            }

            const section = layer.getSectionByInputGuid(inputGuid);

            if (!section) {
                return;
            }

            if (!section.expanded) {
                section.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    const handleSectionsTabExpand = React.useCallback(
        (tab: SectionsTabModel, inputGuid: string) => {
            const section = tab.getSectionByInputGuid(inputGuid);

            if (!section) {
                return;
            }

            let addDelay = false;

            if (!section.expanded) {
                section.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    React.useEffect(() => {
        const subject = documentVisualStore.highlightedInputIdSubject.subscribe(inputGuid => {
            if (!inputGuid) {
                inputStore.setHighlightedInputGuid(null);
                return;
            }

            const tab = tabStore.getTabByInputGuid(inputGuid);

            if (!tab) {
                return;
            }

            tabStore.setActiveTabId(tab.id);

            if (InstanceUtils.isBasicTab(tab)) {
                handleBasicTabExpand(tab, inputGuid);
            } else if (InstanceUtils.isLayersTab(tab)) {
                handleLayersTabExpand(tab, inputGuid);
            } else if (InstanceUtils.isLayersWithSectionsTab(tab)) {
                handleLayersWithSectionsTabExpand(tab, inputGuid);
            } else if (InstanceUtils.isSectionsTab(tab)) {
                handleSectionsTabExpand(tab, inputGuid);
            }
        });

        return () => subject.unsubscribe();
    }, [
        tabStore,
        inputStore,
        documentVisualStore.highlightedInputIdSubject,
        handleBasicTabExpand,
        handleLayersTabExpand,
        handleLayersWithSectionsTabExpand,
        handleSectionsTabExpand
    ]);

    return (
        <>
            {tabStore.tabs.map(tab => (
                <TabRenderer key={tab.id} tab={tab} />
            ))}
        </>
    );
};

export default observer(TabsRenderer);
