import appClient from '../../common/services/AppClient';
import { CancelToken } from 'axios';
import { RenewalSuggestionsStateDto, RenewalSuggestionRequest } from '../types';

export default class ContractsIngestionService {
    async getRenewalSuggestionsState(sessionExtension: string, sessionId: string) {
        const url =
            process.env.REACT_APP_IOTA_URL +
            `contracts-ingestion/renewal-suggestions-state/${sessionExtension}/${sessionId}`;

        return await appClient.get<RenewalSuggestionsStateDto>(url);
    }

    async searchRenewalSuggestions(
        sessionExtension: string,
        sessionId: string,
        suggestionsRequest: RenewalSuggestionRequest,
        cancelToken: CancelToken
    ) {
        const url =
            process.env.REACT_APP_IOTA_URL +
            `contracts-ingestion/renewal-suggestions-search/${sessionExtension}/${sessionId}`;

        return await appClient.post<RenewalSuggestionsStateDto>(url, suggestionsRequest, 'json', cancelToken);
    }

    async disableRenewalSuggestionsSearch(sessionExtension: string, sessionId: string) {
        const url =
            process.env.REACT_APP_IOTA_URL +
            `contracts-ingestion/renewal-suggestions-disable-search/${sessionExtension}/${sessionId}`;

        return await appClient.post<RenewalSuggestionsStateDto>(url);
    }
}
