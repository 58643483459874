/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const CheckIcon: React.FC<IconProps> = ({ width = 24, height = 24, color = '#717887', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M19.4126 6.00024C19.2643 6.00456 19.1235 6.06641 19.02 6.17271L9.14675 16.046L4.98288 11.8821C4.93027 11.8273 4.86726 11.7836 4.79753 11.7535C4.7278 11.7233 4.65276 11.7074 4.5768 11.7066C4.50085 11.7059 4.4255 11.7202 4.35517 11.749C4.28485 11.7777 4.22096 11.8201 4.16724 11.8738C4.11353 11.9276 4.07107 11.9914 4.04236 12.0618C4.01365 12.1321 3.99926 12.2074 4.00003 12.2834C4.0008 12.3594 4.01672 12.4344 4.04686 12.5041C4.07699 12.5739 4.12074 12.6369 4.17553 12.6895L8.74307 17.257C8.85015 17.3641 8.99535 17.4242 9.14675 17.4242C9.29815 17.4242 9.44335 17.3641 9.55042 17.257L19.8274 6.98006C19.9098 6.89984 19.966 6.79662 19.9887 6.68391C20.0115 6.57121 19.9996 6.45426 19.9548 6.34839C19.91 6.24251 19.8342 6.15263 19.7375 6.09051C19.6407 6.02839 19.5275 5.99693 19.4126 6.00024Z"
            fill={color}
        />
    </svg>
);

export default CheckIcon;
