import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import {
    ReloadButton,
    SettingsModal,
    MoreActionsMenu,
    SendContractButton,
    ContractStatus,
    RenewalSuggestionsControls
} from '..';
import './AppHeader.less';

const AppHeader: React.FC = () => {
    const { appName, packageName, sessionStore, contractSubmissionStore } = useAppStore();

    return (
        <div className="contract-ingestion-app-header">
            <div className="left-container">
                <span className="application-name">{appName}</span>
                <span className="package-name">{packageName}</span>
                <ContractStatus />
            </div>

            {!sessionStore.isInitializing && !contractSubmissionStore.contractSent && (
                <div className="right-container">
                    <RenewalSuggestionsControls />
                    <ReloadButton />
                    <SettingsModal />
                    <MoreActionsMenu />
                    <SendContractButton />
                </div>
            )}
        </div>
    );
};

export default observer(AppHeader);
