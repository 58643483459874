import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import { InputRenderer } from '../../components';
import { TabProps } from '../../componentTypes';
import {
    PremiumEntryCalculationMethodInput,
    PremiumEntryPercentInput,
    PremiumEntryPercentOfInput,
    PremiumWithdrawalCalculationMethodInput,
    PremiumWithdrawalPercentInput,
    PremiumWithdrawalPercentOfInput,
    LossEntryCalculationMethodInput,
    LossEntryPercentInput,
    LossWithdrawalCalculationMethodInput,
    LossWithdrawalPercentInput
} from '../../tab_inputs/PortfolioConditionsTabInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils, InputIds } from '../../misc';
import { useTabForm } from '../../hooks';

const InputComponents: Record<string, React.FunctionComponent> = {
    [InputIds.PortfolioConditions.PremiumEntryCalculationMethod]: PremiumEntryCalculationMethodInput,
    [InputIds.PortfolioConditions.PremiumEntryPercent]: PremiumEntryPercentInput,
    [InputIds.PortfolioConditions.PremiumEntryPercentOf]: PremiumEntryPercentOfInput,
    [InputIds.PortfolioConditions.PremiumWithdrawalCalculationMethod]: PremiumWithdrawalCalculationMethodInput,
    [InputIds.PortfolioConditions.PremiumWithdrawalPercent]: PremiumWithdrawalPercentInput,
    [InputIds.PortfolioConditions.PremiumWithdrawalPercentOf]: PremiumWithdrawalPercentOfInput,
    [InputIds.PortfolioConditions.LossEntryCalculationMethod]: LossEntryCalculationMethodInput,
    [InputIds.PortfolioConditions.LossEntryPercent]: LossEntryPercentInput,
    [InputIds.PortfolioConditions.LossWithdrawalCalculationMethod]: LossWithdrawalCalculationMethodInput,
    [InputIds.PortfolioConditions.LossWithdrawalPercent]: LossWithdrawalPercentInput
} as const;

const PortfolioConditionsTabRenderer: React.FC<TabProps> = ({ tab }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab);

    const getComponentRows = React.useCallback(
        (inputs: InputBaseModel[]) =>
            Utils.groupArray(inputs, 3).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        []
    );

    return (
        <LayoutRenderer
            tab={tab}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(PortfolioConditionsTabRenderer);
