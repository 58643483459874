import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'antd';
import { useAppStore } from '../../stores';

const SendContractButton: React.FC = () => {
    const { contractSubmissionStore, controlStateStore } = useAppStore();

    return (
        <Button
            type="primary"
            onClick={() => contractSubmissionStore.sendContract()}
            style={{ height: 40 }}
            disabled={controlStateStore.actionsDisabled}
        >
            Submit
        </Button>
    );
};

export default observer(SendContractButton);
