/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const CheckIconOutlined: React.FC<IconProps> = ({ width = 24, height = 24, color = '#52C41A', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12.3672 20.3281C16.7266 20.3281 20.3359 16.7109 20.3359 12.3594C20.3359 8 16.7188 4.39062 12.3594 4.39062C8.00781 4.39062 4.39844 8 4.39844 12.3594C4.39844 16.7109 8.01562 20.3281 12.3672 20.3281ZM12.3672 19C8.67969 19 5.73438 16.0469 5.73438 12.3594C5.73438 8.67188 8.67188 5.71875 12.3594 5.71875C16.0469 5.71875 19 8.67188 19.0078 12.3594C19.0156 16.0469 16.0547 19 12.3672 19ZM11.5078 16.0703C11.7656 16.0703 11.9844 15.9453 12.1406 15.7031L15.7109 10.0859C15.7969 9.9375 15.8984 9.75781 15.8984 9.58594C15.8984 9.24219 15.5859 9.01562 15.2578 9.01562C15.0625 9.01562 14.8672 9.13281 14.7188 9.36719L11.4766 14.5625L9.9375 12.5781C9.75 12.3281 9.57812 12.2578 9.35938 12.2578C9.02344 12.2578 8.75781 12.5312 8.75781 12.8828C8.75781 13.0469 8.82812 13.2188 8.9375 13.3672L10.8438 15.7031C11.0391 15.9609 11.25 16.0703 11.5078 16.0703Z"
            fill={color}
        />
    </svg>
);

export default CheckIconOutlined;
