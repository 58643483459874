import { makeObservable, computed } from 'mobx';
import InputStore from './InputStore';
import SessionStore from './SessionStore';
import ContractSubmissionStore from './ContractSubmissionStore';

export default class ControlStateStore {
    constructor(
        private readonly inputStore: InputStore,
        private readonly sessionStore: SessionStore,
        private readonly contractSubmissionStore: ContractSubmissionStore
    ) {
        makeObservable(this, {
            inputsDisabled: computed,
            actionsDisabled: computed
        });
    }

    get inputsDisabled() {
        return this.contractSubmissionStore.contractSendingOrSent;
    }

    get actionsDisabled() {
        return (
            this.sessionStore.isInitializing ||
            this.sessionStore.isLoading ||
            this.sessionStore.inProgress ||
            this.contractSubmissionStore.contractSendingOrSent ||
            this.inputStore.inputsLoading
        );
    }
}
