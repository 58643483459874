import * as React from 'react';
import moment from 'moment-timezone';
import { observer } from 'mobx-react-lite';
import { Modal, Table, Button, Tooltip } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ColumnProps } from 'antd/lib/table';
import { useAppStore } from '../../stores';
import { useTableScrollHeight } from '../../../custom_shared/hooks';
import { RenewalSuggestionDto } from '../../types';
import { InputIds, Utils } from '../../misc';
import './RenewalSuggestionsModal.less';

const RenewalSuggestionsModal: React.FC = () => {
    const { tabStore, renewalSuggestionsStore, contractSubmissionStore } = useAppStore();

    const { scrollHeight } = useTableScrollHeight(500);

    const renderColumnValue = (value?: string) => {
        if (!value) {
            return null;
        }

        return (
            <Tooltip title={value}>
                <div className="ellipsis-text">{value}</div>
            </Tooltip>
        );
    };

    const renderLimitsColumn = (suggestion: RenewalSuggestionDto) => {
        let label = '';

        if (suggestion.coverLimit) {
            label += `Cover: ${Utils.formatAmount(suggestion.coverLimit)}`;
        }

        if (suggestion.excessLimit) {
            label += `; Excess: ${Utils.formatAmount(suggestion.excessLimit)}`;
        }

        if (!label.length) {
            return null;
        }

        return renderColumnValue(label);
    };

    const columns: ColumnProps<RenewalSuggestionDto>[] = [
        {
            title: 'Business ID',
            dataIndex: 'businessId',
            key: 'businessId',
            render: (_, suggestion: RenewalSuggestionDto) => renderColumnValue(suggestion.businessId)
        },
        {
            title: 'Period Start',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (_, suggestion: RenewalSuggestionDto) =>
                renderColumnValue(suggestion.startDate ? moment(suggestion.startDate).format('YYYY-MM-DD') : undefined)
        },
        {
            title: 'Cedent',
            dataIndex: 'cedentName',
            key: 'cedentName',
            render: (_, suggestion: RenewalSuggestionDto) => renderColumnValue(suggestion.cedentName)
        },
        {
            title: 'Broker',
            dataIndex: 'brokerName',
            key: 'brokerName',
            render: (_, suggestion: RenewalSuggestionDto) => renderColumnValue(suggestion.brokerName)
        },
        {
            title: 'Main Class',
            dataIndex: 'mainClassOfBusiness',
            key: 'mainClassOfBusiness',
            render: (_, suggestion: RenewalSuggestionDto) => renderColumnValue(suggestion.mainClassOfBusiness)
        },
        {
            title: 'Type',
            dataIndex: 'typeOfParticipation',
            key: 'typeOfParticipation',
            render: (_, suggestion: RenewalSuggestionDto) => renderColumnValue(suggestion.typeOfParticipation)
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (_, suggestion: RenewalSuggestionDto) => renderColumnValue(suggestion.location)
        },
        {
            title: 'Limits',
            key: 'limits',
            render: (_, suggestion: RenewalSuggestionDto) => renderLimitsColumn(suggestion)
        }
    ];

    const onClose = () => {
        renewalSuggestionsStore.setModalVisible(false);
        renewalSuggestionsStore.disableRenewalSuggestionsSearch();
    };

    const onSubmit = () => {
        const businessIdInput = tabStore.generalInformationTab?.inputs.find(
            i => i.id === InputIds.GeneralInformation.BusinessId
        );

        const expiringPeriodStartInput = tabStore.generalInformationTab?.inputs.find(
            i => i.id === InputIds.GeneralInformation.ExpiringPeriodStart
        );

        if (renewalSuggestionsStore.selectedSuggestion && businessIdInput && expiringPeriodStartInput) {
            businessIdInput.setValue(renewalSuggestionsStore.selectedSuggestion.businessId);
            expiringPeriodStartInput.setValue(renewalSuggestionsStore.selectedSuggestion.startDate);
        }

        contractSubmissionStore.sendContract();

        onClose();
    };

    const getRowKey = (suggestion: RenewalSuggestionDto) => {
        return renewalSuggestionsStore.state.suggestions.indexOf(suggestion).toString();
    };

    const rowSelection: TableRowSelection<RenewalSuggestionDto> = {
        type: 'radio',
        columnWidth: 56,
        selectedRowKeys: renewalSuggestionsStore.selectedSuggestion
            ? [getRowKey(renewalSuggestionsStore.selectedSuggestion)]
            : [],
        onChange: (_: React.Key[], selectedSuggestions: RenewalSuggestionDto[]) => {
            if (selectedSuggestions.length) {
                renewalSuggestionsStore.setSelectedSuggestion(selectedSuggestions[0]);
            }
        }
    };

    return (
        <Modal
            className="contract-ingestion-renewal-suggestions-modal"
            title="Renew Business"
            visible={renewalSuggestionsStore.modalVisible}
            maskClosable={false}
            closable={false}
            width={1200}
            destroyOnClose
            centered
            onCancel={onClose}
            footer={[
                <Button key="cancel-button" className="light alpha-dialog-button" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="save-button" type="primary" onClick={onSubmit}>
                    Renew Business
                </Button>
            ]}
        >
            <div className="renewal-suggestions-explanation-container">
                <span className="explanation-title">Existing Business Detected!</span>
                <span className="explanation-text">
                    It appears that the document you uploaded already exists as a business. Do you want to renew this
                    business?
                </span>
            </div>

            <Table
                className="renewal-suggestions-table"
                dataSource={renewalSuggestionsStore.state.suggestions}
                columns={columns}
                pagination={false}
                rowKey={getRowKey}
                rowSelection={rowSelection}
                scroll={{ y: scrollHeight }}
            />
        </Modal>
    );
};

export default observer(RenewalSuggestionsModal);
