import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, Dropdown, Menu } from 'antd';
import { ThreeDotsIcon } from '../../../custom_shared/icons';
import { ClickableIcon } from '../../../custom_shared/components';
import { useAppStore } from '../../stores';

const MoreActionsMenu: React.FC = () => {
    const { actionStore, controlStateStore } = useAppStore();

    const [menuVisible, setMenuVisible] = React.useState(false);

    const moreActionsMenu = (
        <Menu>
            <Menu.Item
                key="export-to-json"
                onClick={() => {
                    actionStore.exportToJson();
                    setMenuVisible(false);
                }}
            >
                Export To JSON
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            placement="bottomRight"
            visible={menuVisible}
            overlay={moreActionsMenu}
            onVisibleChange={setMenuVisible}
            trigger={['click']}
            arrow
        >
            <Tooltip title="More actions">
                <ClickableIcon
                    icon={ThreeDotsIcon}
                    onClick={() => setMenuVisible(true)}
                    disabled={controlStateStore.actionsDisabled}
                />
            </Tooltip>
        </Dropdown>
    );
};

export default observer(MoreActionsMenu);
