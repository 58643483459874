import { CustomSessionRuntimeData } from '../../custom_shared/types';
import TabStore from './TabStore';
import SettingsStore from './SettingsStore';

export default class RuntimeDataStore {
    static RuntimeDataKeys = {
        ActionParams: 'ActionParams',
        LayoutSettings: 'LayoutSettings'
    };

    constructor(
        private readonly tabStore: TabStore,
        private readonly settingStore: SettingsStore
    ) {}

    getRuntimeData(actionParams: Record<string, unknown> = {}): CustomSessionRuntimeData {
        const tabsRuntimeData = this.tabStore.tabs.reduce<CustomSessionRuntimeData>((acc, tab) => {
            acc[tab.id] = { value: JSON.stringify(tab.dto) };
            return acc;
        }, {});

        const additionalRuntimeData = Object.entries(actionParams).reduce<CustomSessionRuntimeData>(
            (acc, [key, value]) => {
                acc[key] = { value };
                return acc;
            },
            {}
        );

        const settings = {
            [RuntimeDataStore.RuntimeDataKeys.LayoutSettings]: {
                value: this.settingStore.layoutSettings ? JSON.stringify(this.settingStore.layoutSettings.dto) : null
            }
        };

        return {
            ...tabsRuntimeData,
            ...additionalRuntimeData,
            ...settings
        };
    }
}
