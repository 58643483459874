import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import { InputRenderer } from '../../components';
import { TabProps } from '../../componentTypes';
import {
    CancellationTermsInput,
    NOCPeriodInput,
    PremiumPaymentWarrantyInput,
    PPWPeriodInput,
    InclusionClausesInput,
    ExclusionClausesInput
} from '../../tab_inputs/OtherConditionsTabInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils, InputIds } from '../../misc';
import { useTabForm } from '../../hooks';

const InputComponents: Record<string, React.FunctionComponent> = {
    [InputIds.OtherConditions.CancellationTerms]: CancellationTermsInput,
    [InputIds.OtherConditions.NOCPeriod]: NOCPeriodInput,
    [InputIds.OtherConditions.PremiumPaymentWarranty]: PremiumPaymentWarrantyInput,
    [InputIds.OtherConditions.PPWPeriod]: PPWPeriodInput,
    [InputIds.OtherConditions.InclusionClauses]: InclusionClausesInput,
    [InputIds.OtherConditions.ExclusionClauses]: ExclusionClausesInput
} as const;

const OtherConditionsTabRenderer: React.FC<TabProps> = ({ tab }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab);

    const getComponentRows = React.useCallback(
        (inputs: InputBaseModel[]) =>
            Utils.groupArray(inputs, 3).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        []
    );

    return (
        <LayoutRenderer
            tab={tab}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(OtherConditionsTabRenderer);
