import { useAppStore } from '../../stores';
import React from 'react';
import { InputIds } from '../../misc';
import { observer } from 'mobx-react-lite';
import './ContractStatus.less';

const contractStatuses = {
    newBusiness: 'New Business',
    businessRenewal: 'Business Renewal',
    incompleteBusinessRenewal: 'Incomplete Business Renewal'
} as const;

const ContractStatus: React.FC = () => {
    const { tabStore, sessionStore } = useAppStore();

    const [status, setStatus] = React.useState('');

    const businessIdInput = React.useMemo(
        () => tabStore.generalInformationTab?.inputs.find(i => i.id === InputIds.GeneralInformation.BusinessId),
        [tabStore.generalInformationTab?.inputs]
    );

    const expiringPeriodStartInput = React.useMemo(
        () =>
            tabStore.generalInformationTab?.inputs.find(i => i.id === InputIds.GeneralInformation.ExpiringPeriodStart),
        [tabStore.generalInformationTab?.inputs]
    );

    const getClassName = () => {
        switch (status) {
            case contractStatuses.newBusiness:
                return 'gray';
            case contractStatuses.businessRenewal:
                return 'blue';
            case contractStatuses.incompleteBusinessRenewal:
                return 'orange';
            default:
                return 'blue';
        }
    };

    React.useEffect(() => {
        if (businessIdInput?.hasValue && expiringPeriodStartInput?.hasValue) {
            setStatus(contractStatuses.businessRenewal);
        } else if (!businessIdInput?.hasValue && !expiringPeriodStartInput?.hasValue) {
            setStatus(contractStatuses.newBusiness);
        } else if (businessIdInput?.hasValue && !expiringPeriodStartInput?.hasValue) {
            setStatus(contractStatuses.incompleteBusinessRenewal);
        }
    }, [businessIdInput?.hasValue, expiringPeriodStartInput?.hasValue]);

    if (sessionStore.isInitializing) {
        return null;
    }

    return (
        <div className={`contract-status-container ${getClassName()}`}>
            <span>{status}</span>
        </div>
    );
};

export default observer(ContractStatus);
